<template>
    <div>
        <vue-title title="Изменить водителя | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Изменить данные по водителю<br><span v-if="driver">{{ driver.fullName }}</span></h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div id="form-container">
                        <div v-if="okMessage" class="alert alert-success mb-3" role="alert">
                            {{ okMessage }}
                        </div>
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="car-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="isLoading"
                                    @field-data-changed="formFieldDataChanged"
                            >
                                <DxGroupItem
                                        :colCount="3"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxSimpleItem>
                                        <DxLabel text="Фото водителя"/>
                                        <template #default>
                                            <div>
                                                <div class="row text-center text-md-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="driverPhotoFileImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px" :alt="driverPhotoFileLable ? 'Загрузка: '+driverPhotoFileLable : ''">
                                                    </div>
                                                    <div class="col-12 mb-3">
                                                        <DxButton
                                                                id="driverPhotoFile-add-external"
                                                                text="Выбрать"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :element-attr="{class: 'mr-2'}"
                                                        />
                                                        <DxButton
                                                                id="driverPhotoFile-del-external"
                                                                text="Удалить"
                                                                type="danger"
                                                                styling-mode="contained"
                                                                @click="onDelFile('driverPhotoFile')"
                                                                :disabled="driverPhotoFileLable == ''"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="driverPhotoFile"
                                                        dialog-trigger="#driverPhotoFile-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/*"
                                                        @value-changed="driverPhotoFileValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                    <DxGroupItem
                                            :colCount="3"
                                            :colSpan="2"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="lastName"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Фамилия"/>
                                            <DxRequiredRule message="Укажите фамилию"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="firstName"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Имя"/>
                                            <DxRequiredRule message="Укажите имя"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="middleName"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Отчество"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :editor-options="{onValueChanged: iinValueChange, maxLength: 12, readOnly: true}"
                                                data-field="iin"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="ИИН"/>
                                            <DxRequiredRule message="Укажите ИИН"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :editor-options="{value: formData.birthday}"
                                                data-field="birthday"
                                                editor-type="dxDateBox"
                                        >
                                            <DxLabel text="Дата рождения"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :editor-options="{
                                                value: formData.sex,
                                                displayExpr: 'name',
                                                valueExpr: 'value',
                                                searchEnabled: false,
                                                items: sexs,
                                                showClearButton: true,
                                                acceptCustomValue: false
                                            }"
                                                :colSpan="1"
                                                data-field="sex"
                                                editor-type="dxSelectBox"
                                        >
                                            <DxLabel text="Пол"/>
                                        </DxSimpleItem>
                                        <DxGroupItem
                                                caption="Контакты"
                                                :colCount="3"
                                                :colSpan="3"
                                        >
                                            <DxSimpleItem data-field="mobile"
                                                          :editor-options="mobileEditorOptions"
                                            >
                                                <DxLabel text="Моб. телефон"/>
                                                <DxPatternRule
                                                        :ignore-empty-value="true"
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                            <DxSimpleItem data-field="whatsApp"
                                                          :editor-options="mobileEditorOptions"
                                            >
                                                <DxLabel text="WhatsApp"/>
                                                <DxPatternRule
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                            <DxSimpleItem data-field="phone"
                                                          :editor-options="phoneEditorOptions"
                                                          cssClass="mb-3"
                                            >
                                                <DxLabel text="Раб. телефон"/>
                                                <DxPatternRule
                                                        :pattern="phonePattern"
                                                        message="Телефон не соответствует формату"
                                                />
                                            </DxSimpleItem>
                                        </DxGroupItem>
                                    </DxGroupItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        caption="Паспортные данные"
                                        :colCount="4"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxSimpleItem>
                                        <DxLabel text="Фото передней части"/>
                                        <template #default>
                                            <div>
                                                <div class="row text-center text-md-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="passportFileFrontImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px" :alt="passportFileFrontLable ? 'Загрузка: '+passportFileFrontLable : ''">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                id="passportFileFront-add-external"
                                                                text="Выбрать"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :element-attr="{class: 'mr-2'}"
                                                        />
                                                        <DxButton
                                                                id="passportFileFront-del-external"
                                                                text="Удалить"
                                                                type="danger"
                                                                styling-mode="contained"
                                                                @click="onDelFile('passportFileFront')"
                                                                :disabled="passportFileFrontImageSource == ''"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="passportFileFront"
                                                        dialog-trigger="#passportFileFront-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/*"
                                                        @value-changed="passportFileFrontValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                    <DxSimpleItem>
                                        <DxLabel text="Фото задней части"/>
                                        <template #default>
                                            <div>
                                                <div class="row text-center text-md-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="passportFileBackImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px" :alt="passportFileBackLable ? 'Загрузка: '+passportFileBackLable : ''">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                id="passportFileBack-add-external"
                                                                text="Выбрать"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :element-attr="{class: 'mr-2'}"
                                                        />
                                                        <DxButton
                                                                id="passportFileBack-del-external"
                                                                text="Удалить"
                                                                type="danger"
                                                                styling-mode="contained"
                                                                @click="onDelFile('passportFileBack')"
                                                                :disabled="passportFileBackImageSource == ''"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="passportFileBack"
                                                        dialog-trigger="#passportFileBack-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/*"
                                                        @value-changed="passportFileBackValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                    <DxGroupItem
                                            :colCount="3"
                                            :colSpan="2"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="citizenshipId"
                                                :editor-options="{
                                                    dataSource: storeCitizenship,
                                                    displayExpr: 'counrtyName',
                                                    valueExpr: 'countryId',
                                                    searchEnabled: true,
                                                    showDataBeforeSearch: true,
                                                    showClearButton: true
                                                }"
                                                editor-type="dxSelectBox"
                                                :colSpan="3"
                                        >
                                            <DxLabel text="Гражданство"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :editor-options="{
                                                value: formData.passportKind,
                                                displayExpr: 'name',
                                                valueExpr: 'value',
                                                searchEnabled: false,
                                                items: passportKinds,
                                                showClearButton: true,
                                                acceptCustomValue: false
                                            }"
                                                :colSpan="1"
                                                data-field="passportKind"
                                                editor-type="dxSelectBox"
                                        >
                                            <DxLabel text="Вид документа"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="passportNomber"
                                                :colSpan="1"
                                        >
                                            <DxLabel text="Номер документа"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="issuedDate"
                                                editor-type="dxDateBox"
                                        >
                                            <DxLabel text="Дата выдачи"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="issuedBy"
                                                :colSpan="3"
                                        >
                                            <DxLabel text="Кем выдан"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        :colCount="2"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxGroupItem
                                            caption="Адрес регистрации"
                                            :colCount="4"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="legalLocalityId"
                                                :editor-options="{
                                                    dataSource: storeLegalLocality,
                                                    displayExpr: 'displayName',
                                                    valueExpr: 'localityId',
                                                    value: formData.legalLocalityId,
                                                    searchEnabled: true,
                                                    showDataBeforeSearch: true,
                                                    showClearButton: true,
                                                    onValueChanged: handleLegalLocalityValueChange
                                                }"
                                                editor-type="dxSelectBox"
                                                :colSpan="3"
                                        >
                                            <DxLabel text="Населенный пункт"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="legalZip"
                                                :editor-options="{onValueChanged: handleLegalZipValueChange}"
                                        >
                                            <DxLabel text="Индекс"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="legalDetailAddress"
                                                :colSpan="4"
                                                :editor-options="{onValueChanged: handleLegalDetailAddressValueChange}"
                                        >
                                            <DxLabel text="Адрес"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                :editor-options="checkBoxIsAddressEqualsOptions"
                                                data-field="isAddressEquals"
                                                editor-type="dxCheckBox"
                                                :colSpan="4"
                                        >
                                            <DxLabel :visible="false"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                    <DxGroupItem
                                            caption="Адрес проживания"
                                            :colCount="4"
                                    >
                                        <DxColCountByScreen :xs="1" :sm="1"/>
                                        <DxSimpleItem
                                                data-field="actualLocalityId"
                                                :editor-options="{
                                                    dataSource: storeActualLocality,
                                                    displayExpr: 'displayName',
                                                    valueExpr: 'localityId',
                                                    value: formData.actualLocalityId,
                                                    searchEnabled: true,
                                                    showDataBeforeSearch: true,
                                                    showClearButton: true,
                                                    autocomplete:  'new-user',
                                                    disabled: isAddressEquals
                                                }"
                                                editor-type="dxSelectBox"
                                                :colSpan="3"
                                        >
                                            <DxLabel text="Населенный пункт"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="actualZip"
                                                :editor-options="{disabled: isAddressEquals, value: formData.actualZip}"
                                        >
                                            <DxLabel text="Индекс"/>
                                        </DxSimpleItem>
                                        <DxSimpleItem
                                                data-field="actualDetailAddress"
                                                :colSpan="4"
                                                :editor-options="{disabled: isAddressEquals, value: formData.actualDetailAddress}"
                                        >
                                            <DxLabel text="Адрес"/>
                                        </DxSimpleItem>
                                    </DxGroupItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        caption="Водительское удостоверение"
                                        :colCount="4"
                                >
                                    <DxColCountByScreen :xs="1" :sm="1"/>
                                    <DxSimpleItem>
                                        <DxLabel text="Фото передней части"/>
                                        <template #default>
                                            <div>
                                                <div class="row text-center text-md-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="driverLicenseFileFrontImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px" :alt="driverLicenseFileFrontLable ? 'Загрузка: '+driverLicenseFileFrontLable : ''">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                id="driverLicenseFileFront-add-external"
                                                                text="Выбрать"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :element-attr="{class: 'mr-2'}"
                                                        />
                                                        <DxButton
                                                                id="driverLicenseFileFront-del-external"
                                                                text="Удалить"
                                                                type="danger"
                                                                styling-mode="contained"
                                                                @click="onDelFile('driverLicenseFileFront')"
                                                                :disabled="driverLicenseFileFrontLable == ''"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="driverLicenseFileFront"
                                                        dialog-trigger="#driverLicenseFileFront-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/*"
                                                        @value-changed="driverLicenseFileFrontValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                    <DxSimpleItem>
                                        <DxLabel text="Фото задней части"/>
                                        <template #default>
                                            <div>
                                                <div class="row text-center text-md-left">
                                                    <div class="col-12 mb-3">
                                                        <img :src="driverLicenseFileBackImageSource" class="img-thumbnail rounded" style="min-height: 200px; min-width: 200px; max-height: 200px" :alt="driverLicenseFileBackLable ? 'Загрузка: '+driverLicenseFileBackLable : ''">
                                                    </div>
                                                    <div class="col-12">
                                                        <DxButton
                                                                id="driverLicenseFileBack-add-external"
                                                                text="Выбрать"
                                                                type="default"
                                                                styling-mode="contained"
                                                                :element-attr="{class: 'mr-2'}"
                                                        />
                                                        <DxButton
                                                                id="driverLicenseFileBack-del-external"
                                                                text="Удалить"
                                                                type="danger"
                                                                styling-mode="contained"
                                                                @click="onDelFile('driverLicenseFileBack')"
                                                                :disabled="driverLicenseFileBackLable == ''"
                                                        />
                                                    </div>
                                                </div>
                                                <DxFileUploader
                                                        id="driverLicenseFileBack"
                                                        dialog-trigger="#driverLicenseFileBack-add-external"
                                                        :multiple="false"
                                                        upload-mode="useForm"
                                                        :visible="false"
                                                        accept="image/*"
                                                        @value-changed="driverLicenseFileBackValueChanged"
                                                />
                                            </div>
                                        </template>
                                    </DxSimpleItem>
                                </DxGroupItem>
                                <DxGroupItem
                                        :colCount="1"
                                >
                                    <DxButtonItem
                                            :button-options="buttonOptions"
                                    />
                                </DxGroupItem>
                            </DxForm>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {Header, Footer} from '../../shared/components';
    import localStore from './store';
    import {httpClient} from '../../shared/services';
    import {
        DxForm,
        DxGroupItem,
        DxColCountByScreen,
        DxLabel,
        DxRequiredRule,
        DxSimpleItem,
        DxButtonItem,
        DxPatternRule
    } from 'devextreme-vue/form';
    import DxButton from 'devextreme-vue/button';
    import { DxFileUploader } from 'devextreme-vue/file-uploader';
    import moment from 'moment';
    import CustomStore from 'devextreme/data/custom_store';

    export default {
        name: "CarrierDriverEdit",
        components: {
            Header,
            Footer,
            DxForm,
            DxGroupItem,
            DxColCountByScreen,
            DxLabel,
            DxRequiredRule,
            DxSimpleItem,
            DxButtonItem,
            DxPatternRule,
            DxButton,
            DxFileUploader
        },
        data() {
            return {
                headerRoutes,
                driver: null,
                driverFiles: null,
                driverId: this.$route.params.driverId,
                formData: {
                    sex: 'Unknown'
                },
                labelLocation: 'top',
                okMessage: "",
                errorMessage: "",
                sexs: [{
                    name: 'Не указан',
                    value: 'Unknown'
                }, {
                    name: 'Мужской',
                    value: 'Male'
                }, {
                    name: 'Женский',
                    value: 'Famale'
                }],
                passportKinds: [{
                    name: 'Не указан',
                    value: 'none'
                }, {
                    name: 'Удостоверение',
                    value: 'IDCard'
                }, {
                    name: 'Паспорт',
                    value: 'Passport'
                }],
                buttonOptions: {
                    text: 'Сохранить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                mobileEditorOptions: {
                    mask: '+X (000) 000 00 00',
                    maskRules: {
                        X: /[1]|[2]|[3]|[4]|[5]|[6]|[7]|[9]|[0]/
                    },
                    //useMaskedValue: true,
                    maskInvalidMessage: 'Номер не соответствует формату мобильного телефона.',
                    autocomplete:  'new-user'
                },
                phoneEditorOptions: {
                    mask: '+X (000) 000 00 00',
                    maskRules: {
                        X: /[1]|[2]|[3]|[4]|[5]|[6]|[7]|[9]|[0]/
                    },
                    //useMaskedValue: true,
                    maskInvalidMessage: 'Номер не соответствует формату телефона.',
                    autocomplete:  'new-user'
                },
                phonePattern: /^((1|2|3|4|5|6|7|9|0)\d{10})$/,
                passportFileFrontImageSource: '',
                passportFileFrontLable: '',
                passportFileFrontId: '',
                passportFileBackImageSource: '',
                passportFileBackLable: '',
                passportFileBackId: '',

                driverLicenseFileFrontImageSource: '',
                driverLicenseFileFrontLable: '',
                driverLicenseFileFrontId: '',
                driverLicenseFileBackImageSource: '',
                driverLicenseFileBackLable: '',
                driverLicenseFileBackId: '',

                driverPhotoFileImageSource: '',
                driverPhotoFileLable: '',
                driverPhotoFileId: '',
                countSaveFiles: 0,
                countDelFiles: 0,
                checkBoxIsAddressEqualsOptions: {
                    text: 'Адрес проживания совпадает с Адресом регистрации',
                    value: this.isAddressEquals,
                    onValueChanged: this.handleIsAddressEqualsValueChange
                },
                isAddressEquals: false,
                storeLegalLocality: null,
                storeActualLocality: null,
                storeCitizenship: null
            }
        },
        mounted() {
            this.$store.dispatch('loading', true).then(() => {
                localStore.dispatch('getDriver', {tokenId: this.Agent.tokenId, driverId: this.driverId}).then(() => {
                    this.driver = localStore.state.driver;
                    let whatsApp = this.$options.filters.changeStartEightInPhone(this.driver.whatsApp);
                    let phone = this.$options.filters.changeStartEightInPhone(this.driver.phone);
                    let mobile = this.$options.filters.changeStartEightInPhone(this.driver.mobile);

                    this.formData = {
                        lastName: this.driver.lastName,
                        firstName: this.driver.firstName,
                        middleName: this.driver.middleName,
                        iin: this.driver.iin,
                        sex: this.driver.sex,
                        birthday: this.driver.birthday,
                        whatsApp: whatsApp,
                        phone: phone,
                        mobile: mobile,
                        legalZip: this.driver.legalAddress?.zip,
                        legalLocalityId: this.driver.legalAddress?.localityId,
                        legalDetailAddress: this.driver.legalAddress?.detailAddress,
                        actualZip: this.driver.address?.zip,
                        actualLocalityId: this.driver.address?.localityId,
                        actualDetailAddress: this.driver.address?.detailAddress,
                        isAddressEquals: this.driver.isAddressEquals,
                        passportKind: this.driver.passportKind,
                        passportNomber: this.driver.passportNomber,
                        issuedDate: this.driver.issuedDate,
                        issuedBy: this.driver.issuedBy,
                        citizenshipId: this.driver.citizenshipId
                    };

                    this.isAddressEquals = this.driver.isAddressEquals;

                    this.storeLegalLocality = new CustomStore({
                        key: 'localityId',
                        byKey: async function (key) {
                            if(key) {
                                if(window.legalLocality) {
                                    if(window.legalLocality.localityId != key) {
                                        return httpClient.get(`v2/Catalog/Localitys/${key}`)
                                            .then((locality) => {
                                                window.legalLocality = locality.data;
                                                return locality.data;
                                            })
                                            .catch(() => {
                                                throw 'Data Loading Error';
                                            });
                                    } else {
                                        return window.legalLocality;
                                    }
                                } else {
                                    return httpClient.get(`v2/Catalog/Localitys/${key}`)
                                        .then((locality) => {
                                            window.legalLocality = locality.data;
                                            return locality.data;
                                        })
                                        .catch(() => {
                                            throw 'Data Loading Error';
                                        });
                                }
                            }
                        },
                        load: (loadOptions) => {
                            //console.log(loadOptions);

                            let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                            return httpClient.get(`v2/Catalog/Localitys?filter={"localityName":{"op":"con","value":"${searchValue}"}}`)
                                .then((data) => {
                                    return {
                                        key: 'localityId',
                                        data: data.data.items,
                                        totalCount: data.totals,
                                        summary: data.totals,
                                        groupCount: data.count,
                                        userData: data.data,
                                    };
                                })
                                .catch(() => {
                                    throw 'Data Loading Error';
                                });
                        }
                    });
                    this.storeActualLocality = new CustomStore({
                        key: 'localityId',
                        byKey: function (key) {
                            if(key) {
                                if(window.actualLocality) {
                                    if(window.actualLocality.localityId != key) {
                                        return httpClient.get(`v2/Catalog/Localitys/${key}`)
                                            .then((locality) => {
                                                window.actualLocality = locality.data;
                                                return locality.data;
                                            })
                                            .catch(() => {
                                                throw 'Data Loading Error';
                                            });
                                    } else {
                                        return window.actualLocality;
                                    }
                                } else {
                                    return httpClient.get(`v2/Catalog/Localitys/${key}`)
                                        .then((locality) => {
                                            window.actualLocality = locality.data;
                                            return locality.data;
                                        })
                                        .catch(() => {
                                            throw 'Data Loading Error';
                                        });
                                }
                            }
                        },
                        load: (loadOptions) => {
                            //console.log(loadOptions);

                            let searchValue = loadOptions.searchValue != null ? loadOptions.searchValue : '';

                            return httpClient.get(`v2/Catalog/Localitys?filter={"localityName":{"op":"con","value":"${searchValue}"}}`)
                                .then((data) => {
                                    return {
                                        key: 'localityId',
                                        data: data.data.items,
                                        totalCount: data.totals,
                                        summary: data.totals,
                                        groupCount: data.count,
                                        userData: data.data,
                                    };
                                })
                                .catch(() => {
                                    throw 'Data Loading Error';
                                });
                        }
                    });
                    this.storeCitizenship = new CustomStore({
                        key: 'countryId',
                        byKey: async function (key) {
                            if(key) {
                                if(window.citizenship) {
                                    if(window.citizenship.countryId != key) {
                                        return httpClient.get(`v2/Catalog/Countrys/${key}`)
                                            .then((country) => {
                                                window.citizenship = country.data;
                                                return country.data;
                                            })
                                            .catch(() => {
                                                throw 'Data Loading Error';
                                            });
                                    } else {
                                        return window.citizenship;
                                    }
                                } else {
                                    return httpClient.get(`v2/Catalog/Countrys/${key}`)
                                        .then((country) => {
                                            window.citizenship = country.data;
                                            return country.data;
                                        })
                                        .catch(() => {
                                            throw 'Data Loading Error';
                                        });
                                }
                            }
                        },
                        load: (loadOptions) => {
                            //console.log(loadOptions);

                            let filter = loadOptions.searchValue != null ? `?filter={"counrtyName":{"op":"con","value":"${loadOptions.searchValue}"}}&take=150` : '?take=150';

                            return httpClient.get(`v2/Catalog/Countrys${filter}`)
                                .then((data) => {
                                    return {
                                        key: 'countryId',
                                        data: data.data.items,
                                        totalCount: data.totals,
                                        summary: data.totals,
                                        groupCount: data.count,
                                        userData: data.data,
                                    };
                                })
                                .catch(() => {
                                    throw 'Data Loading Error';
                                });
                        }
                    });

                    this.getDriverFiles(this.driver.driverId);

                    this.$store.dispatch('loading', false);
                })
            });
        },
        methods: {
            onSubmit: async function () {
                this.okMessage = "";
                this.errorMessage = "";

                this.$store.dispatch('loading', true).then(() => {
                        let tokenId = this.Agent.tokenId;

                    let {
                        lastName,
                        firstName,
                        middleName,
                        iin,
                        sex,
                        birthday,
                        whatsApp,
                        phone,
                        mobile,
                        legalZip,
                        legalLocalityId,
                        legalDetailAddress,
                        actualZip,
                        actualLocalityId,
                        actualDetailAddress,
                        isAddressEquals,
                        driverPhotoFile,
                        passportFileFront,
                        passportFileBack,
                        driverLicenseFileFront,
                        driverLicenseFileBack,
                        passportKind,
                        passportNomber,
                        issuedDate,
                        issuedBy,
                        citizenshipId,
                    } = this.formData;

                    let data = {
                        driverId: this.driverId,
                        lastName: lastName,
                        firstName: firstName,
                        middleName: middleName,
                        iin: iin,
                        sex: sex,
                        birthday: birthday && birthday != undefined && birthday != null && moment(birthday).isValid() ? moment(birthday).format('YYYY-MM-DD') : null,
                        passportKind: passportKind,
                        passportNomber: passportNomber != undefined ? passportNomber : null,
                        issuedDate: issuedDate && issuedDate != undefined && issuedDate != null && moment(issuedDate).isValid() ? moment(issuedDate).format('YYYY-MM-DD') : null,
                        issuedBy: issuedBy != undefined ? issuedBy : null,
                        citizenshipId: citizenshipId != undefined ? citizenshipId : null,
                        whatsApp: whatsApp,
                        phone: phone,
                        mobile: mobile,
                        legalAddress: legalLocalityId != undefined ? {
                            localityId: legalLocalityId != undefined ? legalLocalityId : null,
                            zip: legalZip != undefined ? legalZip : null,
                            detailAddress: legalDetailAddress != undefined ? legalDetailAddress : null,
                        } : null,
                        address: actualLocalityId != undefined ? {
                            localityId: actualLocalityId != undefined ? actualLocalityId : null,
                            zip: actualZip != undefined ? actualZip : null,
                            detailAddress: actualDetailAddress != undefined ? actualDetailAddress : null,
                        } :  null,
                        isAddressEquals: isAddressEquals,
                        fileId: this.driver.fileId
                    };

                    // let isValidIIN = false;
                    //
                    // if(this.validateIIN(iin)) {
                    //     isValidIIN = true;
                    // } else {
                    //     isValidIIN = confirm("ИИН не прошел проверку. Он указан верно?");
                    // }

                    let isValidIIN = true;

                    if(isValidIIN) {
                        if(driverPhotoFile) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        } else {
                            if(this.driverPhotoFileId && this.driverPhotoFileLable == '') {
                                this.countDelFiles = this.countDelFiles+1;
                            }
                        }

                        if(passportFileFront) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        } else {
                            if(this.passportFileFrontId && this.passportFileFrontLable == '') {
                                this.countDelFiles = this.countDelFiles+1;
                            }
                        }

                        if(passportFileBack) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        } else {
                            if(this.passportFileBackId && this.passportFileBackLable == '') {
                                this.countDelFiles = this.countDelFiles+1;
                            }
                        }

                        if(driverLicenseFileFront) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        } else {
                            if(this.driverLicenseFileFrontId && this.driverLicenseFileFrontLable == '') {
                                this.countDelFiles = this.countDelFiles+1;
                            }
                        }

                        if(driverLicenseFileBack) {
                            this.countSaveFiles = this.countSaveFiles+1;
                        } else {
                            if(this.driverLicenseFileBackId && this.driverLicenseFileBackLable == '') {
                                this.countDelFiles = this.countDelFiles+1;
                            }
                        }

                        return httpClient
                            .post(`v2/Carrier/Drivers?tokenId=${tokenId}`, data)
                            .then((response) => { // response
                                if(this.countSaveFiles > 0 || this.countDelFiles > 0) {
                                    if(driverPhotoFile) {
                                        this.saveBase64(driverPhotoFile[0], response.data.driverId, 'ЧлФото', tokenId, this.driverPhotoFileId);
                                    } else {
                                        if(this.driverPhotoFileId && this.driverPhotoFileLable == '') {
                                            this.delFileInDriver(this.driverPhotoFileId, response.data.driverId, tokenId);
                                        }
                                    }

                                    if(passportFileFront) {
                                        this.saveBase64(passportFileFront[0], response.data.driverId, 'УД-З', tokenId, this.passportFileFrontId);
                                    } else {
                                        if(this.passportFileFrontId && this.passportFileFrontLable == '') {
                                            this.delFileInDriver(this.passportFileFrontId, response.data.driverId, tokenId);
                                        }
                                    }

                                    if(passportFileBack) {
                                        this.saveBase64(passportFileBack[0], response.data.driverId, 'УД-П', tokenId, this.passportFileBackId);
                                    } else {
                                        if(this.passportFileBackId && this.passportFileBackLable == '') {
                                            this.delFileInDriver(this.passportFileBackId, response.data.driverId, tokenId);
                                        }
                                    }

                                    if(driverLicenseFileFront) {
                                        this.saveBase64(driverLicenseFileFront[0], response.data.driverId, 'ВУ-З', tokenId, this.driverLicenseFileFrontId);
                                    } else {
                                        if(this.driverLicenseFileFrontId && this.driverLicenseFileFrontLable == '') {
                                            this.delFileInDriver(this.driverLicenseFileFrontId, response.data.driverId, tokenId);
                                        }
                                    }

                                    if(driverLicenseFileBack) {
                                        this.saveBase64(driverLicenseFileBack[0], response.data.driverId, 'ВУ-П', tokenId, this.driverLicenseFileBackId);
                                    } else {
                                        if(this.driverLicenseFileBackId && this.driverLicenseFileBackLable == '') {
                                            this.delFileInDriver(this.driverLicenseFileBackId, response.data.driverId, tokenId);
                                        }
                                    }
                                } else {
                                    this.$router.push({name: 'CarrierDrivers'});
                                }
                            }).catch(this.handleError);
                    } else {
                        this.$store.dispatch('loading', false);
                    }
                }).catch(this.handleError);
            },
            validateIIN(iin) {
                if(iin.length == 12) {
                    //Проверяем контрольный разряд
                    let b1 = Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11);
                    let b2 = Array(3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2);
                    let a = Array();
                    let controll = 0;

                    for (let i = 0; i < 12; i++) {
                        a[i] = iin.substr(i, 1);
                        if (i < 11) controll += a[i] * b1[i];
                    }

                    controll = controll % 11;

                    if (controll == 10) {
                        controll = 0;
                        for (let i = 0; i < 11; i++)
                            controll += a[i] * b2[i];
                        controll = controll % 11;
                    }

                    if (controll == a[11]) {
                        return true;
                    }
                }

                return false;
            },
            handleError: function (error) {
                this.errorMessage = "Ошибка при сохранении данных. Проверьте указанные вами данные.";
                this.$store.dispatch('loading', false);
                console.error(error);
            },
            handleErrorDel: function (error) {
                this.errorMessage = "Ошибка при удалении данных.";
                this.$store.dispatch('loading', false);
                console.error(error);
            },
            handleErrorSaveFile: function (error) {
                this.errorMessage = "Ошибка при сохранении файла. Попробуйте еще раз.";
                console.error(error);
            },
            getBirthday(iin) {
                let day = iin[4] + iin[5];
                let month = iin[2] + iin[3];
                let year = iin[0] + iin[1];
                year = iin[6] > 4 ? '20' + year : '19' + year;

                return year + '-' + month + '-' + day;
            },
            getSex(iin) {
                if(this.validateIIN(iin)) {
                    switch (iin[6]) {
                        case '1':
                        case '3':
                        case '5':
                        case '7':
                            return 'Male';
                        case '2':
                        case '4':
                        case '6':
                        case '8':
                            return 'Famale';
                    }
                }

                return 'Unknown';
            },
            iinValueChange(e) {
                if(e.value && e.value.length == 12) {
                    let birthday = this.getBirthday(e.value);
                    if(moment(birthday).isValid()) {
                        this.formData.birthday = moment(birthday).isValid() ? birthday : null;
                    }

                    let sex = this.getSex(e.value);
                    if(sex != 'Unknown') {
                        this.formData.sex = this.getSex(e.value);
                    }
                }
            },
            formFieldDataChanged() { // e

            },
            saveBase64(file, driverId, fileKind, tokenId, fileId) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // use a regex to remove data url part
                    const base64String = reader.result
                        .replace("data:", "")
                        .replace(/^.+,/, "");

                    this.uploadFile(base64String, file.name, driverId, fileKind, tokenId, fileId);
                };
                reader.readAsDataURL(file);
            },
            uploadFile(base64String, fileName, driverId, fileKind, tokenId, fileId) {
                fileName = this.$options.filters.encodeURL(fileName);

                let url = fileId != "" && fileId != undefined && fileId != null ?
                    `v2/Carrier/Drivers/${driverId}/Files/Upload?fileId=${fileId}&fileName=${fileName}&fileKind=${fileKind}&tokenId=${tokenId}` :
                    `v2/Carrier/Drivers/${driverId}/Files/Upload?fileName=${fileName}&fileKind=${fileKind}&tokenId=${tokenId}`;

                return httpClient
                    .post(url, `"${base64String}"`,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                    .then(() => { // response
                        this.countSaveFiles = this.countSaveFiles-1;

                        if(this.countSaveFiles <= 0) {
                            this.$router.push({name: 'CarrierDrivers'});
                        }
                    }).catch(this.handleErrorSaveFile);
            },
            delFileInDriver(fileId, driverId, tokenId) {
                return httpClient
                    .delete(`v2/Carrier/Drivers/${driverId}/Files/${fileId}?tokenId=${tokenId}`)
                    .then(() => {
                        this.countDelFiles = this.countDelFiles-1;

                        if(this.countSaveFiles <= 0 && this.countDelFiles <= 0) {
                            this.$router.push({name: 'CarrierDrivers'});
                        }
                    }).catch(this.handleErrorDelFile);
            },
            handleErrorDelFile: function (error) {
                this.errorMessage = "Ошибка при удалении файла. Попробуйте еще раз.";
                console.error(error);
            },
            passportFileBackValueChanged(args) {
                if(args.value[0]) {
                    this.formData.passportFileBack = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.passportFileBackImageSource = fileReader.result;
                        this.passportFileBackLable = file.name;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            passportFileFrontValueChanged(args) {
                if(args.value[0]) {
                    this.formData.passportFileFront = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.passportFileFrontImageSource = fileReader.result;
                        this.passportFileFrontLable = file.name;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            driverLicenseFileBackValueChanged(args) {
                if(args.value[0]) {
                    this.formData.driverLicenseFileBack = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.driverLicenseFileBackImageSource = fileReader.result;
                        this.driverLicenseFileBackLable = file.name;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            driverLicenseFileFrontValueChanged(args) {
                if(args.value[0]) {
                    this.formData.driverLicenseFileFront = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.driverLicenseFileFrontImageSource = fileReader.result;
                        this.driverLicenseFileFrontLable = file.name;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            driverPhotoFileValueChanged(args) {
                if(args.value[0]) {
                    this.formData.driverPhotoFile = args.value;
                    const file = args.value[0];
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        this.driverPhotoFileImageSource = fileReader.result;
                        this.driverPhotoFileLable = file.name;
                    };
                    fileReader.readAsDataURL(file);
                }
            },
            onDelFile(name) {
                switch (name) {
                    case 'driverPhotoFile':
                        this.formData.driverPhotoFile = null;
                        this.driverPhotoFileImageSource = '';
                        this.driverPhotoFileLable = '';
                        break;
                    case 'passportFileFront':
                        this.formData.passportFileFront = null;
                        this.passportFileFrontImageSource = '';
                        this.passportFileFrontLable = '';
                        break;
                    case 'passportFileBack':
                        this.formData.passportFileBack = null;
                        this.passportFileBackImageSource = '';
                        this.passportFileBackLable = '';
                        break;
                    case 'driverLicenseFileFront':
                        this.formData.driverLicenseFileFront = null;
                        this.driverLicenseFileFrontImageSource = '';
                        this.driverLicenseFileFrontLable = '';
                        break;
                    case 'driverLicenseFileBack':
                        this.formData.driverLicenseFileBack = null;
                        this.driverLicenseFileBackImageSource = '';
                        this.driverLicenseFileBackLable = '';
                        break;
                }
            },
            loadFile(fileId, name) {
                httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                    .then((file) => {
                        switch (name) {
                            case 'driverPhotoFile':
                                this.driverPhotoFileImageSource = `data:application/png;base64,${file.data}`;
                                break;
                            case 'passportFileFront':
                                this.passportFileFrontImageSource = `data:application/png;base64,${file.data}`;
                                break;
                            case 'passportFileBack':
                                this.passportFileBackImageSource = `data:application/png;base64,${file.data}`;
                                break;
                            case 'driverLicenseFileFront':
                                this.driverLicenseFileFrontImageSource = `data:application/png;base64,${file.data}`;
                                break;
                            case 'driverLicenseFileBack':
                                this.driverLicenseFileBackImageSource = `data:application/png;base64,${file.data}`;
                                break;
                        }
                    })
            },
            getDriverFiles(driverId) {
                this.driverFiles = null;

                localStore.dispatch('getDriverFiles', {tokenId: this.Agent.tokenId, driverId: driverId}).then(() => {
                    this.driverFiles = localStore.state.driverFiles;

                    this.driverFiles.forEach(function (file) {
                        switch (file.fileKind) {
                            case "ЧлФото":
                                this.driverPhotoFileLable = file.name;
                                this.driverPhotoFileId = file.fileId;
                                this.loadFile(file.fileId, 'driverPhotoFile');
                                break;
                            case "УД-П":
                                this.passportFileFrontLable = file.name;
                                this.passportFileFrontId = file.fileId;
                                this.loadFile(file.fileId, 'passportFileFront');
                                break;
                            case "УД-З":
                                this.passportFileBackLable = file.name;
                                this.passportFileBackId = file.fileId;
                                this.loadFile(file.fileId, 'passportFileBack');
                                break;
                            case "ВУ-П":
                                this.driverLicenseFileFrontLable = file.name;
                                this.driverLicenseFileFrontId = file.fileId;
                                this.loadFile(file.fileId, 'driverLicenseFileFront');
                                break;
                            case "ВУ-З":
                                this.driverLicenseFileBackLable = file.name;
                                this.driverLicenseFileBackId = file.fileId;
                                this.loadFile(file.fileId, 'driverLicenseFileBack');
                                break;
                        }
                    }, this);
                })
            },
            handleLegalLocalityValueChange(e) {
                if(this.storeLegalLocality && this.isAddressEquals) {
                    if(this.formData.actualLocalityId != e.value) {
                        this.storeLegalLocality.byKey(e.value).then((dataItem) => {
                            if(dataItem) {
                                this.formData.actualLocalityId = dataItem.localityId;
                            }
                        });
                    }
                }
            },
            handleLegalZipValueChange() {
                if(this.isAddressEquals) {
                    this.formData.actualZip = this.formData.legalZip;
                }
            },
            handleLegalDetailAddressValueChange() {
                if(this.isAddressEquals) {
                    this.formData.actualDetailAddress = this.formData.legalDetailAddress;
                }
            },
            handleIsAddressEqualsValueChange(e) {
                this.isAddressEquals = e.value;

                if(this.isAddressEquals) {
                    this.formData.actualZip = this.formData.legalZip;
                    if(this.formData.actualLocalityId != this.formData.legalLocalityId) {
                        this.formData.actualLocalityId = this.formData.legalLocalityId;
                    }
                    this.formData.actualDetailAddress = this.formData.legalDetailAddress;
                }
            },
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<style lang="scss" scoped>
    .tooltip-lable {
        display: block;
        position: absolute;
        padding-top: 5px;
        margin-left: 20px;
    }
</style>
